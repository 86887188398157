body
{
   position:fixed;
   bottom: 0;
   top:  0;
   left: 0;
   right:0;
   background-image: url(https://images.unsplash.com/photo-1579546929518-9e396f3cc809?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHw%3D&auto=format&fit=crop&w=500&q=60);
   background-repeat: no-repeat;
   background-attachment: fixed;
   background-size: cover;
   overflow-y: scroll;
}
html
{
   overflow-y: auto;
}
.content::-webkit-scrollbar {
   height: 20px; 
}
::-webkit-scrollbar
{
   width: 20px;
}
::-webkit-scrollbar-thumb 
{
   background-color: #d6dee1;
   border-radius: 80px;
   border: 8px solid transparent;
   background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover 
{
   background-color: #a8bbbf;
}
